<template>
  <layout>
    <div class="container px-4 pb-4">
      <div class="columns">
        <div class="column is-half is-offset-one-quarter">
          <div class="tabs">
            <ul>
              <router-link tag="li" :to="{name: 'account'}"><a>Profile</a></router-link>
              <router-link tag="li" :to="{name: 'garmin'}"><a>Garmin</a></router-link>
              <li class="is-active"><a>Plan</a></li>
              <router-link tag="li" :to="{name: 'beta'}"><a>Beta Features</a></router-link>
            </ul>
          </div>
          <div class="content bg-white padded-container">
            <p>Current Subscription Status:<span v-if="!subs.length"> Starter (free) plan.</span></p>
            <b-table :data="subs" :columns="tableColumns" v-if="subs.length > 0"></b-table>
            <b-button class="button is-danger is-fullwidth" v-if="subs.length > 0" @click="cancelSub">Cancel Subscription</b-button>
            <br/>
            <div class="pricing-table">
              <div class="pricing-plan" :class="{'is-active': planIds.length < 1}">
                <div class="plan-header">Starter</div>
                <div class="plan-price">
                  <span class="plan-price-amount">
                    <span class="plan-price-currency">$</span>0
                  </span>/month
                </div>
                <div class="plan-items">
                  <div class="plan-item">View Tracks</div>
                  <div class="plan-item">-</div>
                  <div class="plan-item">-</div>
                  <div class="plan-item">-</div>
                </div>
                <div class="plan-footer">
                  <button v-if="planIds.length < 1" class="button is-fullwidth" disabled="disabled">Current plan</button>
                  <button v-else class="button is-fullwidth" @click="cancelSub">Choose</button>
                </div>
              </div>

              <div class="pricing-plan" :class="{'is-active': planIds.includes(plan.id)}" v-for="plan in plans" v-bind:key="plan.id">
                <div class="plan-header">{{ plan.product.name }}</div>
                <div class="plan-price">
                  <span class="plan-price-amount">
                    <span class="plan-price-currency">$</span>{{ plan.amount }}
                  </span>/{{ plan.interval }}
                </div>
                <div class="plan-items">
                  <div class="plan-item">View Tracks</div>
                  <div class="plan-item" v-for="feature in plan.product.features" v-bind:key="feature">
                    {{ feature|transFeature }}
                  </div>
                  <div class="plan-item" v-if="plan.product.features.length < 2">-</div>
                  <div class="plan-item" v-if="plan.product.features.length < 3">-</div>
                </div>
                <div class="plan-footer">
                  <button v-if="!planIds.includes(plan.id)" class="button is-fullwidth" @click="checkout(plan.id)">Choose</button>
                  <button v-else class="button is-fullwidth" disabled="disabled">Current plan</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  <footer-links />
  </layout>
</template>
<script>
import layout from "@/views/layouts/Default";
import footerLinks from "@/components/shared/footer-links";
import billing from "@/services/api/billing";
import {mapGetters, dispatch} from 'vuex';
import alertHelper from "@/services/helpers/alertHelper"

const STRIPE_PUB_KEY = process.env.VUE_APP_STRIPE_PUB_KEY;
let stripe = Stripe(STRIPE_PUB_KEY);
const featureText = {
  'athlete': 'Athlete Tools',
  'auto_segmentation': 'Auto Segmentation',
  'coach_tools': 'Coach Tools'
}

export default {
  name: "Payment",
  components: {
    layout,
    footerLinks
  },
  data() {
    return {
      plans: [],
      subs: [],
      tableColumns: [
        {
          field: 'plan.name',
          label: 'Plan'
        },
        {
          field: 'status',
          label: 'Status',
        },
        {
          field: 'next_billing_date',
          label: 'Next Bill'
        }
      ]
    };
  },
  mounted() {
    if(this.$route.query.checkout_session_id){
      this.checkoutResultCheck(this.$route.query.checkout_session_id)
    }
    billing.getPlans().then((response) => {
      this.plans = response.data
    })
    billing.getSubs().then((response) =>{
      this.subs = response.data
    })
  },
  methods: {
    checkout(planId) {
      billing.initCheckout(planId).then((response) => {
        const sessionId = response.data.id;
        stripe.redirectToCheckout({
          // Make the id field from the Checkout Session creation API response
          // available to this file, so you can provide it as argument here
          // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
          sessionId: sessionId,
        }).then(function (result) {
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
          console.log(result);
        });
      }).catch((error) => {
        console.log(error);
      });
    },
    checkoutResultCheck(sessionId) {
      billing.checkoutResultCheck(sessionId).then((response) => {
        if(response.data.status === 'wait'){
          setTimeout(() =>{
            return this.checkoutResultCheck(session_id)
          }, 3000)
        }else if(response.data.status === 'fail'){
          alertHelper.alertNotification(this.$store.dispatch, 'Payment failed. Please try another payment method.')
        }else if(response.data.status === 'success'){
          this.$store.dispatch('auth/me');
          alertHelper.successNotification(this.$store.dispatch, 'Subscription successful, thank you!')
        }
      })
    },
    cancelSub(){
      this.$buefy.dialog.confirm({
        title: 'Cancel subscription?',
        message: 'Are you sure you want to cancel your subscription? You will be returned to the starter plan.',
        cancelText: 'No, return.',
        confirmText: 'Yes, cancel.',
        type: 'is-danger',
        onConfirm: () => {
          billing.cancelSub().then((response) => {
            alertHelper.successNotification(this.$store.dispatch, 'Subscription cancelled.')
            this.$store.dispatch('auth/me')
            billing.getSubs().then((response) =>{
              this.subs = response.data
            })
          }).catch(error => {
            alertHelper.alertNotification(this.$store.dispatch, error)
          })

        }
      })
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    planIds(){
      return this.subs.filter(sub => sub.cancel_at_period_end === false).map((sub) => {
        return sub.plan.id
      })
    },
  },
  filters: {
    transFeature(value) {
      if(value in featureText){
        return featureText[value]
      }else{
        return value
      }
    }
  }
};
</script>
