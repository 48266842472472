
<template>
  <layout>
    <div class="container full-height px-4 pb-4">
      <div class="columns">
        <div class="column is-half is-offset-one-quarter">
          <div class="tabs">
            <ul>
              <router-link tag="li" :to="{name: 'account'}"><a>Profile</a></router-link>
              <li class="is-active"><a>Garmin</a></li>
              <router-link tag="li" :to="{name: 'plan'}"><a>Plan</a></router-link>
              <router-link tag="li" :to="{name: 'beta'}"><a>Beta Features</a></router-link>
            </ul>
          </div>
          <div class="content bg-white padded-container">
            <p>
              Login to your Garmin Connect account to automatically sync
              your Garmin sailing tracks with Racing Alpha.
            </p>
            <p>
              <a :href="oauthUrl"><b-button :disabled="user.garmin_connected">Garmin Connect</b-button></a>
            <p>
            <p v-if="user.garmin_connected">Garmin Connected. To disconnect, please remove Racing Alpha from your
            account on garmin.com.</p>
          </div>
        </div>
      </div>
    </div>
    <footer-links />
  </layout>
</template>
<script>
import layout from "@/views/layouts/Default";
import footerLinks from "@/components/shared/footer-links";
import api from "@/services/api/auth"
import { mapState, mapActions } from "vuex";
export default {
  name: 'Garmin',
  components: {
    layout,
    footerLinks
  },
  data() {
    return {
      oauthUrl: '',
      garmin_connected: false,
    }
  },
  beforeCreate() {
    this.$store.dispatch("auth/me");
  },
  created() {
    if(!this.user.garmin_connected){
      api.garminOauthUrl().then(response => {
        this.oauthUrl = response.data
      })
    }
  },
  computed: {
    ...mapState({
      user(state) {
        return state.auth.user;
      },
    }),
  },
}
</script>
