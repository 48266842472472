<template>
  <layout>
    <div class="container full-height px-4 pb-4">
      <div class="columns">
        <div class="column is-half is-offset-one-quarter">
          <div class="tabs">
            <ul>
              <router-link tag="li" :to="{name: 'account'}"><a>Profile</a></router-link>
              <router-link tag="li" :to="{name: 'garmin'}"><a>Garmin</a></router-link>
              <router-link tag="li" :to="{name: 'plan'}"><a>Plan</a></router-link>
              <li class="is-active"><a>Beta Features</a></li>
            </ul>
          </div>
          <div class="content bg-white padded-container">
            <form @submit.prevent="save">
              <b-field>
                <b-switch
                  id="auto_segmentation"
                  v-model="auto_segmentation_enabled"
                  :disabled="!user.enabled_features['auto_segmentation']">
                    Auto Segmentation
                </b-switch>
              </b-field>
              <b-field>
                <b-switch
                  id="show_wind_arrow"
                  v-if="auto_segmentation_enabled"
                  v-model="show_wind_arrow">
                    Wind Direction Arrow
                </b-switch>
              </b-field>
              <b-field>
                <b-button native-type="submit" type="is-primary">Save</b-button>
              </b-field>
            </form>
          </div>
        </div>
      </div>
    </div>
    <footer-links />
  </layout>
</template>
<script>
import {mapState} from 'vuex'
import layout from "@/views/layouts/Default";
import alertHelper from "@/services/helpers/alertHelper"
import footerLinks from "@/components/shared/footer-links";
export default {
    name: 'Beta',
    components: {
      layout,
      footerLinks,
    },
    data() {
      return {
        auto_segmentation_enabled: false,
        show_wind_arrow: false
      }
    },
    beforeCreate() {
      this.$store.dispatch("auth/me");
    },
    created(){
      this.auto_segmentation_enabled = this.user.auto_segmentation_enabled,
      this.show_wind_arrow = this.user.show_wind_arrow
    },
    methods: {
      save(){
        const userData = {
          auto_segmentation_enabled: this.auto_segmentation_enabled,
          show_wind_arrow: this.show_wind_arrow
        }
        this.$store.dispatch("auth/userupdate", userData).catch(() => {
          alertHelper.alertNotification(this.$store.dispatch, 'Could not set features. Please try again.')
        })
      }
    },
    computed: {
      ...mapState({
        user(state) {
          return state.auth.user;
        },
      }),
    }
}
</script>
