import axios from 'axios'

export default {
  getPlans(){
    return axios.get('/billing/plans/')
  },
  getSubs(){
    return axios.get('/billing/subs/')
  },
  cancelSub(){
    return axios.post('/billing/cancel/')
  },
  initCheckout(price_id){
    return axios.get(`/billing/init-checkout/?price_id=${price_id}`)
  },
  checkoutResultCheck(sessionId){
    return axios.get(`/billing/checkout-result/?checkout_session_id=${sessionId}`)
  }
}
