var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout',[_c('div',{staticClass:"container full-height px-4 pb-4"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-half is-offset-one-quarter"},[_c('div',{staticClass:"tabs"},[_c('ul',[_c('li',{staticClass:"is-active"},[_c('a',[_vm._v("Profile")])]),_c('router-link',{attrs:{"tag":"li","to":{name: 'garmin'}}},[_c('a',[_vm._v("Garmin")])]),_c('router-link',{attrs:{"tag":"li","to":{name: 'plan'}}},[_c('a',[_vm._v("Plan")])]),_c('router-link',{attrs:{"tag":"li","to":{name: 'beta'}}},[_c('a',[_vm._v("Beta Features")])])],1)]),_c('div',{staticClass:"content bg-white padded-container"},[_c('form',{staticClass:"account-form",on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('b-field',{attrs:{"label":"First Name"}},[_c('b-input',{class:{
                  'validation-error': _vm.myaccount.errors.first_name,
                  disabled: !_vm.myaccount.edit,
                },attrs:{"id":"first_name","placeholder":"your first name"},model:{value:(_vm.myaccount.first_name),callback:function ($$v) {_vm.$set(_vm.myaccount, "first_name", $$v)},expression:"myaccount.first_name"}})],1),_c('span',{staticClass:"validation-error-text"},[_vm._v(_vm._s(_vm.myaccount.errors.first_name))]),_c('b-field',{attrs:{"label":"Last Name"}},[_c('b-input',{class:{
                  'validation-error': _vm.myaccount.errors.last_name,
                  disabled: !_vm.myaccount.edit,
                },attrs:{"id":"last_name","placeholder":"your last name"},model:{value:(_vm.myaccount.last_name),callback:function ($$v) {_vm.$set(_vm.myaccount, "last_name", $$v)},expression:"myaccount.last_name"}})],1),_c('span',{staticClass:"validation-error-text"},[_vm._v(_vm._s(_vm.myaccount.errors.last_name))]),_c('b-field',{attrs:{"label":"Email"}},[_c('b-input',{class:{
                  'validation-error': _vm.myaccount.errors.email,
                  disabled: !_vm.myaccount.edit,
                },attrs:{"id":"email","placeholder":"your email"},model:{value:(_vm.myaccount.email),callback:function ($$v) {_vm.$set(_vm.myaccount, "email", $$v)},expression:"myaccount.email"}})],1),_c('span',{staticClass:"validation-error-text"},[_vm._v(_vm._s(_vm.myaccount.errors.email))]),_c('b-field',{attrs:{"label":"Password"}},[_c('b-input',{class:{
                  'validation-error': _vm.myaccount.errors.password,
                  disabled: !_vm.myaccount.edit,
                },attrs:{"id":"password","type":"password","placeholder":"**********"},model:{value:(_vm.myaccount.password),callback:function ($$v) {_vm.$set(_vm.myaccount, "password", $$v)},expression:"myaccount.password"}})],1),_c('span',{staticClass:"validation-error-text"},[_vm._v(_vm._s(_vm.myaccount.errors.password))]),_c('b-field',{attrs:{"label":"Primary Boat Class"}},[_c('b-select',{model:{value:(_vm.myaccount.primary_boat_class),callback:function ($$v) {_vm.$set(_vm.myaccount, "primary_boat_class", $$v)},expression:"myaccount.primary_boat_class"}},_vm._l((_vm.boatClasses),function(option,index){return _c('option',{key:index,domProps:{"value":option}},[_vm._v(" "+_vm._s(option)+" ")])}),0)],1),_c('b-switch',{attrs:{"id":"email_notifications"},model:{value:(_vm.myaccount.email_notifications),callback:function ($$v) {_vm.$set(_vm.myaccount, "email_notifications", $$v)},expression:"myaccount.email_notifications"}},[_vm._v(" Email Notifications ")]),_c('b-field',{attrs:{"label":"Upload a profile image"}},[_c('vue-dropzone',{ref:"profileDropzone",attrs:{"id":"dropzone","options":_vm.dropzoneOptions},on:{"vdropzone-sending":_vm.sendingEvent,"vdropzone-queue-complete":_vm.queueComplete,"vdropzone-drop":_vm.dropped,"vdropzone-mounted":_vm.dropMounted}})],1),_c('div',{staticClass:"button-container"},[_c('b-button',{class:{ 'btn-disabled': _vm.$v.$invalid },attrs:{"type":"is-primary","native-type":"submit"},on:{"click":_vm.save}},[_vm._v("Save")])],1)],1)])])])]),_c('footer-links')],1)}
var staticRenderFns = []

export { render, staticRenderFns }