<template>
  <div class="chart">
    <div>
      <div class="chart-header">
        <div class="y-axios-value">
          {{ displayedMode }}
        </div>
        <div class="time-duration" v-if="duration !== 0">
          Duration: {{ duration }}
        </div>
        <div class="current-position" v-if="currentPosition !== 0">
          Timestamp: {{ currentPosition | moment("timezone", tracks[0].tz, "HH:mm z") }}
        </div>
        <div class="yot-max-values">
          <div v-for="(val, index) in getAggregates" :key="index">
            <div
              v-if="val"
              style="display: flex; align-items: center; margin-right: 10px"
            >
              <div
                class="track-info-card-boat"
                v-bind:style="{ backgroundColor: colors[index] }"
              >
                <img
                  src="../../assets/svg/yot.svg"
                  alt="svg"
                  width="30"
                  height="30"
                />
              </div>
              <span>{{ val }}</span>
            </div>
          </div>
          <ul class="math-type">
            <li
              v-for="(val, index) in mathType"
              :key="index"
              @click="changeMathType(index)"
              v-bind:class="{
                'selected-math-type': index === selectedMathType,
              }"
            >
              {{ val }}
            </li>
          </ul>
        </div>
      </div>
      <div class="chart-box" @mouseleave="mouseLeft">
        <apexchart
          :options="options"
          width="100%"
          height="150"
          :series="getSeries"
          ref="chart"
        />
        <div
          v-if="dragging"
          class="cancel-zooming"
          @click="cancelZoom"
        >
          ←
        </div>
        <Golden
          v-if="dragging && !isJournal && (track_id || race_id)"
          :track_id="track_id"
          :race_id="race_id"
          ref="golden"
          :start="dragStart"
          :end="dragEnd"
          @toggleGoldStarSection="toggleGoldStarSection"
          @goldStarSaved="goldStarSaved"
        />
        <div v-if="isJournal" class="removesegment" @click="removeSegment">
          <b-icon icon="trash" class="is-warning"> </b-icon>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Golden from "../Golden";
import { COLORS } from "@/constants";
import { ANIMATION_INTERVAL_MS } from "@/mixins/MapMixin";
import VueApexCharts from "vue-apexcharts";
import moment from "moment-timezone";
import _ from "lodash";

export default {
  props: [
    "tracks",
    "currentTime",
    "pause",
    "selectionMode",
    "lastDataMode",
    "colors",
    "units",
    "speed",
    "track_id",
    "race_id",
    "hoverTime",
    "trackJournal",
    "indicators",
    "modes",
    "changeMathType",
    "mathType",
    "selectedMathType",
    "getAggregates",
    "highlightedGoldStar",
    "clickedGoldStar",
  ],
  components: {
    Golden,
    apexchart: VueApexCharts,
  },
  data() {
    const instance = this
    return {
      options: {
        chart: {
          width: "100%",
          height: 200,
          events: {
            click: this.mouseClick,
            mouseMove: this.mouseHover,
            zoomed: this.dragComplete,
            // I couldn't get mouseLeave to work for some reason
          },
          type: "line",
          animations: {
            enabled: true,
            easing: "linear",
            dynamicAnimation: {
              speed: 1000,
            },
          },
          zoom: {
            type: "x",
            enabled: true,
            autoScaleYaxis: true,
          },
          toolbar: {
            show: false,
          },
          parentHeightOffset: 0,
          selection: {
            enabled: false,
            type: "x",
          },
        },
        colors: this.colors,
        states: {
          active: {
            filter: {
              type: "none",
            },
          },
        },
        stroke: {
          show: true,
          curve: "smooth",
          width: 2,
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        markers: {
          size: 0,
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              return val.toFixed(0);
            },
          },
        },
        xaxis: {
          type: "datetime",
          labels: {
            formatter: function (value, timestamp, opts) {
              return moment(timestamp).tz(instance.tracks[0].tz).format("HH:mm:ss");
            },
          },
          crosshairs: {
            show: false,
          },
        },
        tooltip: {
          shared: true,
          y: {
            formatter: function (val) {
              return val;
            },
          },
          x: {
            show: false,
          },
          custom: () => {
            return "<div></div>";
          },
          marker: {
            show: false,
          },
        },
      },
      width: 0,
      // A flag to identify when the selection is being updated
      // to ignore the click handler after the selection.
      selectionUpdating: false,

      lastAnimationTime: null,
      startT: 0,
      endT: 0,
      series: [],
      displayedMode: "",
      duration: 0,
      currentPosition: 0,
      movementTime: 0,

      dragStart: 0,
      dragEnd: 0,
      isJournal: false,

      // show transparent line and value if hover on strip chart
      isShowOpacityLine: false,

      // Whether to show the segment names on the chart or not
      zoomedSegment: null,

      localTracks: null,

      // store past zoom levels as stack so we can pop back just one
      zoomStack: [],
    };
  },
  created() {
    this.localTracks = _.cloneDeep(this.tracks);
  },
  computed: {
    // instead of just whether something has been selected, compute based on stack
    dragging() {
      return (this.zoomStack.length !== 0)
    },
    getSeries() {
      const tracks = this.tracks;
      if (!tracks) {
        return [];
      }
      if (!this.localTracks) {
        this.localTracks = _.cloneDeep(tracks);
      }
      console.log("localTracks", this.localTracks);
      let seriesMode = this.selectionMode;
      let noSeries =
        this.selectionMode === "none" || this.selectionMode === "tags";
      if (noSeries) {
        // use the last selectionMode
        seriesMode = this.lastDataMode;
      }
      this.series = [];
      for (let trackIndex = 0; trackIndex < this.localTracks.length; trackIndex++) {
        const track = this.localTracks[trackIndex];
        // Limit the trackpoints based on the selection
        let selectionTrackpoints = track.trackpoints;
        if (this.dragging) {
          selectionTrackpoints = selectionTrackpoints.filter(
            (trackpoint) => {
              const time = new Date(trackpoint.time).getTime();
              return time >= this.dragStart && time <= this.dragEnd;
            }
          );
        }
        let seriesData = selectionTrackpoints.map((trackpoint) => {
          let dataPoint = {
            x: trackpoint["time"],
            y: trackpoint[seriesMode],
          };
          return dataPoint;
        });
        const series = {
          name: `track_${trackIndex}`,
          type: "line",
          data: seriesData,
        };
        this.series.push(series);
      }
      this.displayedMode = seriesMode;
      console.log(this.series);
      return this.series;
    },

    journal() {
      if (this.trackJournal) {
        const journal = this.trackJournal.filter((j) => {
          if (!this.zoomedSegment && !this.dragging) {
            return j.jType === "star";
          } else {
            return j.content === this.zoomedSegment;
          }
        });
        return journal;
      }
    },
  },
  mounted() {
    this.$emit("isLoading", false);
    this.width = this.$refs.chart.chart.dimensions.xAxisWidth;
  },

  watch: {
    pause: function (val) {
      if (!val) {
        this.moveLine();
      }
    },

    currentTime(val) {
      this.currentPosition = new Date(val)//.toTimeString().substr(0, 8);
      this.$refs.chart.removeAnnotation("x_0");
      this.$refs.chart.addXaxisAnnotation(
        {
          id: "x_0",
          x: val,
          strokeDashArray: 0,
          borderColor: "#008ffb",
        },
        true
      );
    },

    highlightedGoldStar(data) {
      this.$refs.chart.removeAnnotation("goldStarRange");
      if (!data) {
        return;
      }
      const {start, end, text} = data;
      this.$refs.chart.addXaxisAnnotation(
        {
          id: "goldStarRange",
          x: start,
          x2: end,
          fillColor: '#008ffb',
          borderColor: '#24292e',
          strokeDashArray: 0,
          opacity: 0.3,
          label: {
            text,
            textAnchor: 'left',
            orientation: 'horizontal',
          }
        }
      );
    },

    clickedGoldStar(journal) {
      if (!journal) {
        return;
      }
      this.zoomInJournal(journal);
    },

    hoverTime: function (val) {
      if (!val) {
        return;
      }
      this.$refs.chart.removeAnnotation("hoverX");
      this.isShowOpacityLine =
        this.currentTime + this.startT === val ? false : true;
      this.movementTime = val;
      this.$refs.chart.addXaxisAnnotation(
        {
          id: "hoverX",
          x: val,
          strokeDashArray: 0,
          borderColor: "grey",
          opacity: 0.3,
          width: 10,
        },
        false
      );
    },

    movementTime: function (val) {
      this.currentPosition = new Date(val)//.toTimeString().substr(0, 8);
    },
  },

  methods: {
    removeSegment() {
      if (confirm("Delete journal segment?")) {
        const journalToDelete = this.trackJournal.find(
          (j) => j.content === this.zoomedSegment
        );
        this.$store.dispatch("journal/deleteJournal", journalToDelete.id);
        this.cancelZoom();
      }
    },

    /**
     * Run the animation by moving the time line at 60fps.
     * @param {UnixTimestamp} timestamp The new time.
     */
    moveLine(timestamp) {
      if (this.pause) {
        this.lastAnimationTime = null;
        return;
      }
      let animationInterval = ANIMATION_INTERVAL_MS;
      if (!this.lastAnimationTime) {
        this.lastAnimationTime = timestamp;
      } else {
        animationInterval = timestamp - this.lastAnimationTime;
      }

      const animationIntervalDelta = ANIMATION_INTERVAL_MS - animationInterval;

      if (animationIntervalDelta > 1) {
        requestAnimationFrame(this.moveLine);
        return;
      }
      this.lastAnimationTime = timestamp;

      const currentTime = this.currentTime + animationInterval * this.speed;
      this.setTime(currentTime);
      requestAnimationFrame(this.moveLine);
    },

    mouseClick(event, chartContext, config) {
      if (this.selectionUpdating) {
        this.selectionUpdating = false;
        return;
      }
      let { seriesIndex, dataPointIndex } = config;
      let dataPoint = this.getSeries[seriesIndex].data[dataPointIndex];
      const clickedTime = new Date(dataPoint.x).getTime();
      this.setTime(clickedTime);
      this.$emit("setClickedTime", clickedTime);
    },

    mouseLeft() {
      this.isShowOpacityLine = false;
      this.movementTime = this.currentTime + this.startT;
      this.$refs.chart.removeAnnotation("hoverX");
      this.$emit("mouseLeft");
    },

    mouseHover(event, chartContext, config) {
      let { seriesIndex, dataPointIndex } = config;
      if (seriesIndex === -1) {
        // sometimes the hover is not in the series
        return;
      }
      let dataPoint = this.getSeries[seriesIndex].data[dataPointIndex];
      if (!dataPoint) {
        return;
      }
      this.isShowOpacityLine = true;
      this.movementTime = new Date(dataPoint.x).getTime();
      this.$emit("setOpacityTime", this.movementTime);
    },

    setTime(currentTime) {
      this.$emit("setCurrentTime", currentTime);
    },

    cancelZoom() {
      this.zoomStack.pop();
      if (this.zoomStack.length > 0) {
        let obj = this.zoomStack.slice(-1)[0]

        // if gold star, then display star
        if (obj.zoomedSegment) {
          this.$emit("goldStarClicked", obj.zoomedSegment);
          this.zoomStack.pop();
        } else {
          this.$emit("goldStarClicked", null);
          this.isJournal = obj.isJournal;
          this.duration = obj.duration;
          this.selectionUpdating = true;
          this.$emit("selectSection", { start: obj.start, end: obj.end });
          this.dragStart = obj.dragStart;
          this.dragEnd = obj.dragEnd;
          this.zoomedSegment = obj.zoomedSegment
        }
      } else {
        this.$refs.chart.resetSeries();
        this.$emit("selectSection", null);
        this.$emit("goldStarClicked", null);
        this.duration = 0;
        this.toggleGoldStarSection();
        this.isJournal = false;
        this.zoomedSegment = null;
      }
    },

    zoomInJournal(journal) {
      const start = new Date(journal.s_point).getTime();
      const end = new Date(journal.e_point).getTime();
      this.$refs.chart.zoomX(start, end);
      let date = new Date(0);
      date.setSeconds((end - start) / 1000);
      this.duration = date.toISOString().substr(11, 8);
      this.dragStart = start;
      this.dragEnd = end;
      this.isJournal = true;
      this.zoomedSegment = journal.content;

      // mark name in zoom stack so we know to show gold star label
      let index = this.zoomStack.findIndex(obj => (obj.start === start && obj.end === end));
      if (index !== -1) {
        this.zoomStack[index].zoomedSegment = journal;
      }
    },

    dragComplete(event, axes) {
      console.log({axes});
      this.isJournal = false;
      const start = axes.xaxis.min;
      const end = axes.xaxis.max;
      if (!start || !end) {
        console.log("dragComplete invalid range", {start, end});
        return;
      }
      let date = new Date(0);
      date.setSeconds((end - start) / 1000);
      this.duration = date.toISOString().substr(11, 8);
      this.selectionUpdating = true;
      this.$emit("selectSection", { start: start, end: end });
      this.dragStart = start;
      this.dragEnd = end;

      this.zoomStack.push({
        start: start,
        end: end,
        duration: this.duration,
        dragStart: this.dragStart,
        dragEnd: this.dragEnd,
        isJournal: this.isJournal,
        zoomedSegment: null

      });
    },

    toggleGoldStarSection() {
      this.$emit("toggleIsShowGoldStar");
    },

    goldStarSaved(journal){
      this.$emit('goldStarSaved', journal)
    }
  },
};
</script>
<style lang="sass">
.cancel-zooming
  font-size: 16px
  position: absolute
  top: 40px
  right: 10px
  cursor: pointer
  color: black
  border: 1px solid black
  padding: 0px 7px
  border-radius: 50%
  z-index: 1

.chart-line
  width: 1.5px
  height: 100%
  background-color: grey
  position: absolute
  top: -13px
  transform: scaleY(0.815)
  z-index: 6

.chart-line-value
  position: absolute
  top: -15px
  color: grey
  font-weight: 500

.chart-line-opacity
  width: 1.5px
  height: 100%
  background-color: grey
  position: absolute
  opacity: 0.3
  top: -13px
  transform: scaleY(0.815)
  pointer-events: none
  z-index: 7

.chart-line-opacity-value
  position: absolute
  top: -15px
  color: grey
  opacity: 0.5

#container_UserInteraction
  opacity: 0

.time-duration
  color: black
  font-weight: bold

.current-position
  color: black
  font-weight: bold

ellipse
  display: none

.apexcharts-xaxistooltip
  display: none

// Hide the series markers when hovering
.apexcharts-series-markers
  display: none
</style>

<style lang="sass" scoped>
.map-player-container
  .chart
    width: 100%
    .chart-header
      .yot-max-values
        .math-type
          margin-right: 10px
.removesegment
  position: absolute
  top: 40px
  left: 40px
  width: 85%
  cursor: pointer

</style>