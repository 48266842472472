<template>
  <layout>
    <div class="full-height">
      <div class="container padded-container">
        <h3 class="is-size-3">Upload</h3>
        <form class="account-form" @submit.prevent="upload">
          <b-field
            label="Boat Class"
            :message="errors.boat_name"
            :type="errors.boat_name ? 'is-danger' : null"
          >
            <b-select v-model="boat_name">
              <option
                v-for="(option, index) in boatClasses"
                :value="option"
                :key="index"
              >
                {{ option }}
              </option>
            </b-select>
          </b-field>
          <b-field
            label="Visibility"
            :message="errors.permissions"
            :type="errors.permissions ? 'is-danger' : null"
          >
            <b-radio v-model="permissions" native-value="PUBLIC"
              >Public</b-radio
            >
            <b-radio v-model="permissions" native-value="PRIVATE"
              >Private</b-radio
            >
          </b-field>
          <b-field label="Track File" :message="errors.fileAdded"
            :type="errors.fileAdded ? 'is-danger' : null "
          >
            <vue-dropzone
              ref="myVueDropzone"
              :key="componentKey"
              id="dropzone"
              v-on:vdropzone-sending="sendingEvent"
              v-on:vdropzone-complete="endUploading"
              @vdropzone-file-added="vfileAdded"
              @vdropzone-removed-file="vremoved"
              :options="dropzoneOptions"
            >
            </vue-dropzone>
          </b-field>
          <b-field>
            <b-button
              class="is-primary button button-submit btn-info"
              native-type="submit"
              value="Save"
            >
              <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false"></b-loading>
              Upload
            </b-button>
          </b-field>
        </form>
      </div>
    </div>
    <footer-links />
  </layout>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import layout from "@/views/layouts/Default.vue";
import footerLinks from "@/components/shared/footer-links";
import { required } from "vuelidate/lib/validators";

export default {
  name: "Upload",
  components: {
    vueDropzone: vue2Dropzone,
    layout,
    footerLinks,
  },
  validations: {
    boat_name: { required },
    fileAdded: {required },
  },
  data: function () {
    return {
      componentKey: 0,
      removedFile: false,
      fileAdded: null,
      sending: false,
      boat_name: null,
      location: null,
      permissions: "PUBLIC",
      dropzoneOptions: {
        url: process.env.VUE_APP_API + "/tracks/",
        thumbnailWidth: 150,
        dictDefaultMessage: "Drop or click to select .fit, .gpx, .csv files. Compatible with TruSail and Vakeros CSV files.",
        headers: {
          Authorization: "Bearer " + this.$store.getters["auth/user"].access,
        },
        maxFiles: 1,
        acceptedFiles: ".fit,.gpx,.csv",
        addRemoveLinks: false,
        autoProcessQueue: false,
      },
      errors: {},
      boatClasses: [
        "29er", 
	"470", 
	"49er", 
	"49erFX", 
	"C420", 
	"CFJ", 
	"Formula Kite", 
	"i420", 
	"J70", 
	"Laser", 
	"Melges 24", 
	"Nacra 17", 
	"Viper 640", 
	"Z420", 
	"Other",
      ],
      isLoading: false
    };
  },
  created() {
    const user = this.$store.getters["auth/user"]
    let primary_boat_class = user.primary_boat_class;
    console.log("boat_class", primary_boat_class)
    if (primary_boat_class) {
      this.boat_name = primary_boat_class;
    }
  },
  methods: {
    vremoved(file, xhr, error) {
      this.fileAdded = false;
      // window.toastr.warning('', 'Event : vdropzone-removedFile')
    },
    vfileAdded(file) {
      this.fileAdded = true;
    },
    forceRerender() {
      this.componentKey += 1;
    },
    upload: function (event) {
      this.errors = {
        boat_name: null,
        fileAdded: null,
        permissions: null,
      };
      if (!this.$v.$invalid) {
        this.$refs.myVueDropzone.processQueue();
      } else {
        if (!this.$v.boat_name.required)
          this.errors.boat_name = "Boat Class required";
        if (!this.fileAdded) this.errors.fileAdded = "Track File required";
      }
    },
    sendingEvent(file, xhr, formData) {
      this.isLoading = true
      formData.append("file", file);
      formData.append("boat_name", this.boat_name);
      formData.append("permissions", this.permissions);
      this.sending = true;
    },
    endUploading() {
      if (this.sending == true) {
        this.$store.dispatch("tracks/getTracks", { reset: true });
        this.$router.push({
          name: "tracks",
        });
      } else {
        this.forceRerender();
      }
      this.isLoading = false;
    },
  },
};
</script>

<style>
</style>